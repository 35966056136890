import React, { Component } from 'react';
import { Card, Header, Body, Button, Image, Navbar, Row, Col, Nav } from 'react-bootstrap'
import Ticker from 'react-ticker'

class TickerBanner extends Component {
     state = {
        reptiles: [
            {
                id: "1",
                nm: "Interior Designing |"
            },
            {
                id: "2",
                nm: "Color & Painting |"
            },

            {
                id: "3",
                nm: "Housing & Apartment Building |"
            },



            {
                id: "4",
                nm: " CCTV Installation |"
            },

            {
                id: "5",
                nm: " Computer, Hardware, & Networking |"
             },


               {
                id: "6",
                nm: " Photocopy Machine Installation |"
             },

               {
                id: "7",
                nm: "A/C |"
             }




        ]
    }
    render() {
        return (

            <div className="fixed-top" >

                <Nav className="justify-content-center pt-3 text-white" style={{ backgroundColor: " #669999"}} >
                    <Nav.Item>
                    <b>Aloknagar 31, Baneshwor, Kathmandu, Nepal</b>
                    </Nav.Item>

                </Nav>


                <Ticker  mode="chain">
                    {({ index }) => (

                        <Nav style={{ color: "white", fontSize: "1.3vw",  backgroundColor: "#ff4d4d" }}>

                            {
                                this.state.reptiles.map((rep) => {
                                    return <p key={rep.id} className="text-decoration-none m-1" style={{whiteSpace: "nowrap"}}>{rep.nm}</p>
                                })
                            }
                        </Nav>
                    )
                    }
                </Ticker>
            </div >

         );
    }
}

export default TickerBanner