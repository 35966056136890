import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AnimatePresence } from 'framer-motion'
import './App.css'

import {  Switch, Route, useLocation } from 'react-router-dom'



import Home from './components/MainPage/home'
import About from './components/about/about'
import Contact from './components/contact/contact'
import TickerBanner from './components/TickerBanner'

import Services from './components/services/services'
import Jobs from './components/jobs/jobs'



function App() {
  const location = useLocation()

    return (
      <div>
        <TickerBanner />
        <main style={{ overflowY: "", position: "relative"}} >
        <AnimatePresence>
              <Switch location={location} key={location.pathname}>
              <Route path="/" exact component={Home} />
                <Route path="/about" exact  component={About} />
                <Route path="/contact" exact component={Contact} />
              <Route path="/services" exact component={Services} />
               <Route path="/jobs" exact component={Jobs} />
              </Switch>
            </AnimatePresence>
        </main>
        </div>

     );
  }


export default App;


