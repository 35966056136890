import React, { Component } from 'react';
import FooterDiv from '../footer/footer'
import NavBar from '../navbar/navbar'
import { motion } from 'framer-motion'
import { Card, Form,  Button } from 'react-bootstrap'

import Background from './assets/jobs.jpeg'

const pageVariants = {
    initial: {
        opacity: 0,
        y: "100vw",
        scale: 0.9
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 1.1,
        y: "-100vw",
        scale: 1.1
    }
}

const pageTransitions = {
    // duration: 1.3,
    type: "linear",
    // stiffness: 8,
    ease: "anticipate",
    duration: 2

}

class Jobs extends Component {

    render() {
        return (

             <motion.div
                 initial="initial"
                animate="in"
                 exit="out"
                variants={pageVariants}
                transition={pageTransitions}
                className=" position-absolute"
                style={{ marginTop: "2rem" }}>

                <NavBar />

               <Card className="bg-dark text-white text-center">
                    <Card.Img src={Background} alt="Card image" style={{width: "100vw"}} />
                    <Card.ImgOverlay>
                        <Form className="col-sm-12 col-md-12 col-lg-6 container" style={{ marginTop: "10rem" }}>
                                <Card.Title>There are currently no jobs available. Please check back later.</Card.Title>
                            <Form.Group controlId="formBasicEmail">
                                {/* <Form.Control type="email" placeholder="Enter email" /> */}
                                <Form.Text className="text-muted">
                                {/* We'll never share your email with anyone else. */}
                                </Form.Text>
                            </Form.Group>
                            {/* <Button variant="primary" type="submit">
                                Submit
                            </Button> */}
                            </Form>









                    </Card.ImgOverlay>
                </Card>

                <FooterDiv />
            </motion.div>
         );
    }
}

export default Jobs;