import React from 'react';
import { Link } from 'react-router-dom'
import { Navbar, Nav, Form, Image } from 'react-bootstrap'

import Logo from './assets/logo.png'

class Navigation extends React.Component {

    render() {
        return (

            <Navbar expand="lg"  style={{marginTop: '4rem'}}>

                <Navbar.Toggle aria-controls="basic-navbar-nav right" />


                         <Navbar.Brand className="">
                    <Link to="/">
                        <Image className="logoAndLinkIcon" src={Logo} style={{ width: "3rem" }} />

                    </Link>
                    {/* <Nav.Item className="navbarTitle">Titanium Builders</Nav.Item> */}
                </Navbar.Brand>

                    <Navbar.Collapse id="basic-navbar-nav">

                    <Nav className="ml-auto" style={{fontSize: "1.5rem"}}>


                            <Nav.Link className="logoAndLinkIcon">
                                <Link to="/about" style={{color: "black", textDecorationLine: "none"}}>ABOUT</Link>
                            </Nav.Link>



                         <Nav.Link className="logoAndLinkIcon">
                                <Link to="/services" style={{color: "black", textDecorationLine: "none"}}>SERVICES</Link>
                            </Nav.Link>

                            {/* <Nav.Link>
                                <Link to="/services" style={{color: "black"}}>Services</Link>
                            </Nav.Link> */}

                     {/* <NavDropdown className="logoAndLinkIcon" title="Services" id="basic-nav-dropdown" style={{}}>
                                <NavDropdown.Item className="subLink">
                                    <Link to="/services/homebuilding" style={{color: "black", textDecorationLine: "none", }}>Home Building</Link>
                                </NavDropdown.Item>
                                   <NavDropdown.Divider />
                                <NavDropdown.Item className="subLink">
                                    <Link to="/services/interior" style={{color: "black", textDecorationLine: "none", }}>Interior Designing</Link>
                                </NavDropdown.Item>
                                   <NavDropdown.Divider />
                                <NavDropdown.Item className="subLink">
                                    <Link to="/services/roads" style={{color: "black", textDecorationLine: "none", }}>Roads and Bridges</Link>
                            </NavDropdown.Item>
                             <NavDropdown.Divider />
                              <NavDropdown.Item className="subLink">
                                    <Link to="/services/ac" style={{color: "black", textDecorationLine: "none", }}>Air Conditioning</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                              <NavDropdown.Item className="subLink">
                                    <Link to="/services/painting" style={{color: "black", textDecorationLine: "none", }}>Color and Painting</Link>
                                </NavDropdown.Item>



                    </NavDropdown> */}

                          {/* <Nav.Link className="logoAndLinkIcon">
                                <Link to="/news" style={{color: "black", textDecorationLine: "none"}}>NEWS</Link>
                            </Nav.Link> */}

                            <Nav.Link  className="logoAndLinkIcon">
                                <Link to="/jobs" style={{color: "black", textDecorationLine: "none"}}>JOBS</Link>
                        </Nav.Link>


                            {/* <NavDropdown className="logoAndLinkIcon" title="Projects" id="basic-nav-dropdown">
                                <NavDropdown.Item className="subLink">
                                    <Link to="/projects/upcoming" style={{color: "black", textDecorationLine: "none"}}>Upcoming</Link>
                                </NavDropdown.Item>
                                   <NavDropdown.Divider />
                                <NavDropdown.Item className="subLink">
                                    <Link to="/projects/current" style={{color: "black", textDecorationLine: "none"}}>Current</Link>
                                </NavDropdown.Item>
                                   <NavDropdown.Divider />
                                <NavDropdown.Item className="subLink">
                                    <Link to="/projects/completed" style={{color: "black", textDecorationLine: "none"}}>Completed</Link>
                                </NavDropdown.Item>

                    </NavDropdown> */}



                            <Nav.Link className="logoAndLinkIcon">
                                <Link to="/contact"  style={{color: "black", textDecorationLine: "none" }}>CONTACT</Link>
                        </Nav.Link>


                    </Nav>



                    <Form inline className="ml-auto">

                        {/* <FormControl type="text" placeholder="Search" className="mr-2" /> */}

                        {/* <Button variant="outline-info" style={{ color: "black" }}>Search</Button> */}

                    </Form>
                        </Navbar.Collapse>

                </Navbar>


         )
    }
}

export default Navigation;