import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { Form, Button, Input, FormGroup, Label, Row, Col, CardImg} from 'reactstrap'

import emailjs from 'emailjs-com'
import Recaptcha from 'react-recaptcha'
import { motion } from 'framer-motion'
import Navbar from '../navbar/navbar'
import FooterDiv from '../footer/footer'

import Background from './assets/mailConst.jpeg'

const pageVariants = {
    initial: {
        opacity: 0,
        y: "100vw",
        scale: 0.9
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 1.1,
        y: "-100vw",
        scale: 1.1
    }
}

const pageTransitions = {
    // duration: 1.3,
    type: "linear",
    // stiffness: 8,
    ease: "anticipate",
    duration: 2

}


class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {

            firstName: "",
            lastName: "",
            email: "",
            title: "",
            phoneNumber: "",
            description: "",

            firstNameError: "",
            lastNameError: "",
            emailError: "",
            titleError: "",
            descriptionError: "",
            phoneNumberError: "",

            recaptchaLoad: false,
            isVerified: false,

        }

    }

    recaptchaLoaded = () => {
        this.setState({ recaptchaLoad: true });
    }

    verifiedRecaptcha = (response) => {
        if (response) {
            this.setState({ isVerified: true });
        }
    }

     validateForm = () => {

        let firstNameError = "";
        let lastNameError = "";
        let emailError = "";
        let descriptionError = "";
        let titleError = "";
        let phoneNumberError = "";


        if (!this.state.firstName) {
            firstNameError = "first name is empty"
        }

        if (!this.state.lastName) {
            lastNameError = "first name is empty"
        }

        if (!this.state.description) {
            descriptionError = "Description is empty"
        }

        if (!this.state.email.includes("@", ".com")) {
            emailError = "invalid email"
        }

         if (!this.state.title) {
            titleError = "title  is empty"
         }

         if (!this.state.phoneNumber) {
            phoneNumberError = "Number is empty"
        }

        if (emailError || firstNameError || lastNameError || descriptionError || titleError || phoneNumberError) {
            this.setState({ emailError, firstNameError, lastNameError, descriptionError, titleError, phoneNumberError })
            return false
        }
        return true
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const { recaptchaLoad, isVerified } = this.state
         const isValid = this.validateForm()


        if (recaptchaLoad && isVerified && isValid) {
            const { firstName, lastName, title, email, description, phoneNumber } = this.state
            const templateParams = {
                firstName,
                lastName,
                email,
                title,
                phoneNumber,
                description

            }
            emailjs.send(
                'gmail',
                'tbuilders',
                templateParams,
                'user_HeO2ybIbY7V0tpUwqhh8H'
            )
            alert("Success")
            this.props.history.push("/")

        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }



    render() {
        const { firstName, lastName, email, description, title, phoneNumber } = this.state
        return (
             <motion.div
                 initial="initial"
                animate="in"
                 exit="out"
                variants={pageVariants}
                transition={pageTransitions}
                style={{ position: "", marginTop: "rem" }}
                className=""
            >
                        <Navbar  />

                <Row className="m-0 contactRow">

                     <CardImg src={Background} alt="Card image cap" className="contactForm1" />
                    <Col sm={6} className="position-absolute">
                        <Form onSubmit={this.handleSubmit}
                            className="container justify-content-center contactForm"
                            style={{background: "white", opacity: "0.8", borderRadius: "", padding: "1rem"}}
                        >

                            <FormGroup>


                                    <Label >First Name</Label>
                                    <Input name="firstName" type="text" className="form-control"
                                        value={firstName}
                                        placeholder="First name"
                                        onChange={this.handleChange}
                                    />

                            </FormGroup>
                            <div>{this.state.firstNameError}</div>


                               <FormGroup>
                                    <Label>Last Name</Label>
                                    <Input name="lastName" type="text" className="form-control"
                                        value={lastName}
                                        placeholder="Last name"
                                        onChange={this.handleChange}
                                    />



                            </FormGroup>
  <div>{this.state.lastNameError}</div>
                            <FormGroup>


                                    <Label >Email address</Label>
                                    <Input name="email" type="email" className="form-control"
                                        value={email}
                                        placeholder="name@example.com"
                                        onChange={this.handleChange}
                                    />




                            </FormGroup>

                              <div>{this.state.emailError}</div>

                             <FormGroup>
                                    <Label >Title</Label>
                                    <Input name="title" className="form-control" value={title}
                                        onChange={this.handleChange} />


                            </FormGroup>
                            <div>{this.state.titleError}</div>


                        <FormGroup>


                                    <Label >Phone Number</Label>
                                    <input name="phoneNumber" type="text" className="form-control"
                                        value={phoneNumber}
                                        placeholder="00000-00000"
                                        onChange={this.handleChange}
                                    />




                            </FormGroup>

                              <div>{this.state.phoneNumberError}</div>




                   <FormGroup  className="">


                                    <Label >Description</Label>
                                    <textarea name="description" className="form-control" value={description}
                                        rows="2"
                                        onChange={this.handleChange}
                                    ></textarea>


                            </FormGroup>
                            <div>{this.state.descriptionError}</div>


                            <FormGroup style={{height: ""}} className="">

                                <Recaptcha

                                        sitekey="6LdmE8MZAAAAADNRNnup9dn_spXlHSnc9oMCEULA"
                                        render="explicit"
                                        onloadCallback={this.recaptchaLoaded}
                                        verifyCallback={this.verifiedRecaptcha}
                                    />

                            </FormGroup>


                            <FormGroup  className="">

                                    <Link to="/"><Button c-lassName="btn btn-secondary" type="button">Cancel</Button></Link>{' '}
                                    <Button color="info" type="submit">Submit</Button>

                            </FormGroup>


                        </Form>


                    </Col>
                <Col sm={6}>


                    </Col>


                </Row>


                <FooterDiv />

            </motion.div>
        )
    }
}

export default Contact;