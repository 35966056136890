import React, { Component } from 'react'

import Navbar from '../navbar/navbar'

import LandingPage from './LandingPage'
import { motion } from 'framer-motion'


const pageVariants = {
    initial: {
        opacity: 0,
        y: "100vw",
        scale: 0.9
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 1.1,
        y: "-100vw",
        scale: 1.1
    }
}

const pageTransitions = {
    // duration: 1.3,
    type: "linear",
    // stiffness: 8,
    ease: "anticipate",
    duration: 2

}



class Home extends Component {
    state={
    showLogin: false
  }

  toggleModal = this.setState({
                showLogin: !this.state.showLogin
              })

    render() {
        return (
            <motion.div initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransitions}
                style={{position: "absolute", marginTop: "2rem"}}


            >
                            <Navbar className="" />
                        <LandingPage className=""  />

            </motion.div>
         );
    }
}


export default Home;