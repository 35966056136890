import React, { Component } from 'react';
import { Card, Header, Body, Button, Image, Nav, Row, Col } from 'react-bootstrap'
import Video2 from './assets/Wood Cutting Using Jigsaw.mp4'
import ReactPlayer from 'react-player'

import BootstrapModal from '../formModal'
import PhoneNumber from 'react-phone-number';
import FooterDiv from '../footer/footer'


class LandingPage extends Component {
    state = {  }
    render() {
        return (

            <Card className="p-0" style={{background: "#d6d9d3", border: "none"}}>
                <ReactPlayer className="LandingPageVideo"  url={Video2} playing loop={true} width='100%' height='100%' alt="Card image" />
                <Card.ImgOverlay className="col-sm-12 col-md-12 col-lg-4 text-center cardFront"
                    style={{
                        opacity: "0.7",
                        border: "none",
                        background: "#F5F5F5",
                        height: "32vw",
                        marginTop: "9rem",
                        marginLeft: "5rem",
                        padding: "1rem",
                        fontSize: "2rem"
                    }}>
                        <Card.Header className="navbarTitle" style={{ fontSize: "3rem", background: "none"}}><b>Titanium Builders and Suppliers.</b></Card.Header>
                    <Card.Text style={{fontSize: "1.75rem"}}>
                        <p>Call us today at {" "}
                            <PhoneNumber className="phoneNumber" number="+9779823627399" isLinked={true} style={{ textDecorationLine: "none" }} />{" "}{" "}
                                <i className="fas fa-phone-square" style={{ color: "green" }}>  </i>{' '}
                                    and ask us about your dream project.
                                    We are here to help you 24x7. If you'd like to  connect with us for free estimate or any other equiries via  email
                                    {" "}<i style={{ color: "navy" }} class="fas fa-envelope-open-text"></i>
                                </p>
                    </Card.Text>
                    <div className="contactButton text-white">
                    <BootstrapModal

                        toggle={this.toggleModal}
                        showModal={this.state.showLogin}
                        />
                    </div>
                </Card.ImgOverlay>

                <FooterDiv />
            </Card>

         )
    }
}

export default LandingPage