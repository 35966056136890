import React, { Component } from 'react';
import { Card, Header, Body, Title, Tab, Tabs, Jumbotron, Image, Row, Col, CardDeck, CardColumns, Nav } from 'react-bootstrap'
import Navbar from '../navbar/navbar'
import { motion } from 'framer-motion'


import Footer from '../footer/footer'
import ReactPlayer from 'react-player'
import { icons } from '../../services'

import Image1 from './assets/servicesImage.jpeg'
import Image2 from './assets/servicesImage2.jpeg'
import Image3 from './assets/servicesImage3.jpg'


const pageVariants = {
    initial: {
        opacity: 0,
        y: "100vw",
        scale: 0.9
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 1.1,
        y: "-100vw",
        scale: 1.1
    }
}

const pageTransitions = {
    // duration: 1.3,
    type: "linear",
    // stiffness: 8,
    ease: "anticipate",
    duration: 2

}

class MissionAndServices extends Component {
    state = {  }
    render() {
        return (

                  <motion.div
                 initial="initial"
                animate="in"
                 exit="out"
                variants={pageVariants}
                transition={pageTransitions}
                className=" position-absolute"
                style={{marginTop: "2rem"}}




            >

                <Navbar />

                <div>
                    <Row className="m-0 text-center">


                        <Card className="text-white col-sm-12 col-md-12 col-lg-7 p-0">
                            <Card.Img src={Image1} alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Card.Text></Card.Text>
                            </Card.ImgOverlay>
                            </Card>


                                <Card className="bg-dark text-white col-sm-12 col-md-12 col-lg-5">
                                <Card.Body className="mt-5">
                                    <Card.Title><b>WE BUILD YOUR HOME</b></Card.Title>
                                    <Card.Subtitle className="mt-2"></Card.Subtitle>
                                    <Card.Text className="m-5 p-3 text-muted text-center">
                                    Titanium builders and suppliers is a talented, professional builder that strives to build the finest homes for only the best customers in the industry. We provide a world-class experience from the day you dream it up, to well past the time you have settled in. You’re going to love it so much that you’re going to want to do it all over again!


We use the highest quality materials, put together with the most talented and loyal craftsmen in the industry. We live and breathe our company’s unique culture and strive to listen to you first and foremost. It is our strong desire to create a pleasurable, as well as a memorable building experience that you will happily share with your friends and family.

Your home is a reflection of the individual that owns it, and ultimately the builder who helped cultivate that dream and made it possible. It is one of the most important purchases you will make in your lifetime; it is very important to you, and therefore, it is very important to us.
                                    </Card.Text>
                                    {/* <Card.Link href="#">Card Link</Card.Link>
                                    <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                                </Card>




                    </Row>



                     <Row className="m-0 text-center">




                                <Card className="bg-dark text-white col-sm-12 col-md-12 col-lg-5">
                                <Card.Body className="mt-5">
                                    <Card.Title><b>WE BUILD ROADS</b></Card.Title>
                                    <Card.Subtitle className="mt-2"></Card.Subtitle>
                                    <Card.Text className="m-5 p-3  text-muted text-center">

We are driven to improving the safety and protective quality of roads for the benefit of all road users. We develop roads for the benefit of heavy industry and motorists through to the most vulnerable road users like pedestrians and cyclists.
                                    We are committed to using today’s technology to create instant infrastructure that helps connect communities and strengthen economies, but always to help save lives. We never forget that it is people who use roads.

We continue to innovate and develop solutions that deliver higher quality roads that are more environmentally friendly, are longer lasting, and are quicker and cheaper than conventional infrastructure.

At Titanium we make a difference, one road at a time.

                                    </Card.Text>
                                    {/* <Card.Link href="#">Card Link</Card.Link>
                                    <Card.Link href="#">Another Link</Card.Link> */}
                                </Card.Body>
                                </Card>


<Card className="text-white col-sm-12 col-md-12 col-lg-7 p-0">
                            <Card.Img src={Image2} alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Card.Text></Card.Text>
                            </Card.ImgOverlay>
                            </Card>


                    </Row>


                     <Row className="m-0">


                        <Card className="text-black col-sm-12 col-md-12 col-lg-12 p-0 text-center">
                            <Card.Img className="servicesPage" src={Image3} alt="Card image" style={{height: "45vw"}} />
                            <Card.ImgOverlay >
                                <Card.Header className="servicesCardHeader" style={{ fontSize: "2rem", background: "none", border: "none" }}>OUR SERVICES</Card.Header>

                                <Card className="m-3 servicesCard" style={{
                                    width: "30vw", float: "right",
                                    textAlign: "left", background: "none",
                                    border: "none",
                                    marginTop: "5rem", fontSize: "2rem", background: "none"
                                }}>

                                <Card.Text style={{marginTop: "3rem"}}>

                                    <li>Interior Designing</li>
                                    <li>False Ceiling</li>
                                    <li>Wallpaper</li>
                                    <li>Shop Decoration</li>
                                    <li>Swimming Pool Construction</li>
                                    <li>Garden Construction</li>
                                    <li>Furniture and Furnishing</li>
                                    <li>Electrical Work</li>

                                </Card.Text>
                                    <Card.Text></Card.Text>
                                </Card>
                                 <Card className="m-3 servicesCard" style={{
                                    width: "30vw", float: "right",
                                    textAlign: "left", background: "none",
                                    border: "none", marginTop: "5rem",
                                     fontSize: "2rem", background: "none"
                                }}>

                                <Card.Text style={{marginTop: "3rem"}}>


                                    <li>Metal and Aluminium</li>
                                    <li>Plumbing and Sanitation</li>
                                    <li>Color and Painting</li>
                                    <li>Marble and Tiles</li>
                                    <li>Air Conditioning</li>
                                    <li>CCTV Installation</li>
                                    <li>Computer and Networking</li>
                                    <li>Photocopy Installation</li>
                                </Card.Text>
                                    <Card.Text></Card.Text>
                                    </Card>
                            </Card.ImgOverlay>
                            </Card>





                    </Row>



                </div>



                <Footer className=" position-absolute"/>
</motion.div>
         );
    }
}

export default MissionAndServices;