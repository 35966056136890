import React, { Component } from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap'

import { Link } from 'react-router-dom'


// -----------Icons-----------
import Facebook from './assets/facebook.png'
import Instagram from './assets/insta.png'
import Twitter from './assets/twitter.png'
import YouTube from './assets/youTubeIcon.png'


class footer extends Component {
    render() {
        return (

      <div  className="p-5 " style={{background: "#a3c2c2"}}>

  <Row style={{background: "#a3c2c2"}}>

    <Col  md={4}>

              </Col>

              <Col md={8} className="text-center">

     <Row  style={{background: "#a3c2c2"}}>
    <Col md={6} md={3}>
                    <Card  style={{border: "none", background: "#a3c2c2"}}>
                      <Card.Body>
                         <Card.Text>
                        <Card.Title>IMAGES</Card.Title>
                         <Link to="/projects/upcoming" style={{color: "black", textDecorationLine: "none"}}>Gallery</Link>
                        </Card.Text>
                        </Card.Body>
                </Card>
    </Col>

    <Col md={6} md={3}>
   <Card style={{border: "none", background: "#a3c2c2"}}>
                      <Card.Body>
                         <Card.Text>
                        <Card.Title>PROJECTS</Card.Title>
                          <Link to="/projects/upcoming" style={{ color: "black", textDecorationLine: "none" }}>Current</Link>
                          <br />
                          <Link to="/projects/upcoming" style={{ color: "black", textDecorationLine: "none" }}>Upcoming</Link>
                            <br />
                          <Link to="/projects/upcoming" style={{color: "black", textDecorationLine: "none"}}>Completed</Link>
                        </Card.Text>
                        </Card.Body>
                </Card>
    </Col>
    <Col md={6} md={3}>
              <Card style={{border: "none", background: "#a3c2c2"}}>
                      <Card.Body>
                         <Card.Text>
                        <Card.Title>MISSION</Card.Title>

                        </Card.Text>
                        </Card.Body>
                </Card>
    </Col>
              <Col md={6} md={3}>
     <Card style={{border: "none", background: "#a3c2c2"}}>
                      <Card.Body>
                        <Card.Text>
                        <Card.Title>WHY TITANIUM?</Card.Title>

                        </Card.Text>
                        </Card.Body>
                </Card>
    </Col>
  </Row>
              </Col>






            </Row>



             <Row style={{textAlign: "center", paddingTop: "3rem"}}>
    <Col xs>© Copyright 2020 Titanium Builders Inc.</Col>
    <Col xs={{ order: 1 }}> All rights reserved.</Col>
              <Col xs={{ order: 12 }} >
                <div style={{ textAlign: "right", border: "none" }}>
                        <Link to="" alt="" className="mr-2">
                            <Image style={{ width: "1.5rem"}} className="mediaIcon" src={Facebook} alt="Media"></Image>
                        </Link>

                            <a rel="noreferrer" href="https://instagram.com/titaniumbuilders2020?igshid=lurxzoxb0a7y" target="_blank"><Image style={{ width: "1.5rem"}} className="mediaIcon mr-2" src={Instagram} alt="Media"/></a>

                        <Link to="" alt="" className="mr-2">
                     <Image style={{width: "1.5rem"}} className="mediaIcon" src={Twitter} alt="Media" />
                        </Link>
                        <a rel="noreferrer" href="" target="_blank"><Image style={{ width: "1.5rem"}} className="mediaIcon mr-2" src={YouTube} alt="Media"/></a>
                </div>
              </Col>
  </Row>
</div>
        );
    }
}

export default footer;