import React, { Component } from 'react';
import { Card, Header, Body, Title, Tabs, Tab, Button, Footer, Image, Row, Col, CardDeck, CardColumns, Jumbotron } from 'react-bootstrap'
import { motion } from 'framer-motion'
import Navbar from '../navbar/navbar'
import FooterDiv from '../footer/footer'
import ReactPlayer from 'react-player'
import video from './assets/video.mp4'
const images = [
    {
        id: "1",
         image: "home3.jpeg",
        title: "TRANSPARENCY",
        obj: "TRUST",
     class: "fas fa-home",
        desc: "We earn trust the old-fashioned way.  Operating with integrity, we focus on open communication, responsiveness, and getting things done right.  We understand that your best interest is also ours. We are passionate about our work and about working together. We can’t help but share this joyfulness with everyone. Whether the project is large or small, we believe in our clients."

    },
    {
        id: "2",

         image: "grayback.jpeg",

         class: "fas fa-lightbulb",
         title: "VISION",
        desc: "We are genuinely passionate about your vision, and deeply engaged in how you will live in your home. The real magic of our work is aligning your goals to building realities and budget. WE thrive on identifying custom design and building solutions – always thinking expansively and pragmatically to bring your dream to life. We focus on keeping things as simple as possible."
    },
      {
        id: "4",
        image: "handshake.jpeg",
        title: "PARTNERSHIP",
        obj: "Trust",
        class: "fas fa-handshake",
        desc: " We embrace innovation, it’s at our core. We always put users’ needs first – we go the extra mile. Innovation can be anything from a brilliant new concept to a minor, but no less important, improvement in how we do things. We believe in a friendly and open attitude. We also believe in the strength of people and competencies coming together to achieve something great."



    },
      {
        id: "3",
        image: "management.jpg",
        title: "MANAGEMENT",
        obj: "Trust",
        class: "fas fa-users",
        desc: "Our “round table” approach to project management combines our team’s talents in site planning, construction, interior design, painting, and many more, to ensure deep expertise and integrated solutions throughout the life of your project. We value challenges because they produce innovations. We commit to approaching problems with enthusiasm and optimism."

    },
]

const pageVariants = {
    initial: {
        opacity: 0,
        y: "100vw",
        scale: 0.9
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 1.1,
        y: "-100vw",
        scale: 1.1
    }
}

const pageTransitions = {
    type: "linear",
    ease: "anticipate",
    duration: 2

}

class About extends React.Component {
    render() {
         return (
            <motion.div
                 initial="initial"
                animate="in"
                 exit="out"
                variants={pageVariants}
                transition={pageTransitions}
                 className="position-absolute"
                 style={{ marginTop: "2rem" }}



            >

                <Navbar />


                <Card className="" style={{ color: "white", border: "none", background: "#d6d9d3"}}>
                     <ReactPlayer  url={video} playing loop={true} width='100%' height='100%' alt="Card image" />

                    <Row className="m-0 position-absolute w-100 ">

                         <div className="col-lg-12" style={{
                             background: "none",
                             border: "none",
                             marginTop: "10rem",
                             textAlign: "center",

                         }}>

                                   <Card.Text className="" style={{ background: "", color: "white", fontSize: "5rem" }}>
                                         <b>THE WAY WE DO BUSINESS</b>
                                     </Card.Text>


                                    {/* <div> */}
                                     {/* It has been our mission to make custom home design and construction, an experience that our clients enjoy. With a genuine enthusiasm for what do,
                                     we apply in- depth building and design experience to ensure you a rewarding and personalized process from start to finish.
                                     This is what sets us apart — shapes our values, and the way we do business. */}
                                    {/* </div> */}
                                 </div>

                     </Row>


                     <Row className="position-absolute m-0 aboutCardRow">
                        <Col>

                        {
                        images.map((images, index) => {
                            return <Card key={index} className="col-sm-12 col-md-12 col-lg-3 bottom-0 aboutCard"
                                  // pr-0 pl-0
                                style={{
                                    border: "none",
                                    display: "inline-block",
                                    background: "none",
                                    marginTop: "20rem",
                                    color: "white"




                                }}>

                                <Card className="" style={{
                                    background: "none",
                                    border: "none",


                                }}>
                                        <Card.Body style={{  fontSize: "1rem", background: "none"}}>
                                                 <Card.Header style={{  background: "none", fontSize: "3rem", color: "green",  border: "none", textAlign: ""}}>
                                            <i className={images.class}  />

                                        </Card.Header>
                                          <Card.Text style={{textAlign: "center", color: ""}}>
                                            <b>{images.desc}</b>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>


                                </Card>

                        })
                    }

                         </Col>

                     </Row>

                 </Card>
                 <div className="footerAbout">

  <FooterDiv/>
                 </div>




  </motion.div>
         );
    }


    }


export default About;