import React from 'react'
import emailjs from 'emailjs-com'
import { Form, FormGroup, Button, Modal } from 'react-bootstrap'
import Recaptcha from 'react-recaptcha'
import { Link } from 'react-router-dom'

class FormModal extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            showHide: false,
            firstName: "",
            lastName: "",
            email: "",
            title: "",
            phoneNumber: "",
            description: "",

            firstNameError: "",
            lastNameError: "",
            emailError: "",
            titleError: "",
            phoneNumberError: "",
            descriptionError: "",

            recaptchaLoad: false,
            isVerified: false,

        }
    }

    recaptchaLoaded = () => {
        this.setState({ recaptchaLoad: true });
    }

    verifiedRecaptcha = (response) => {
        if (response) {
            this.setState({ isVerified: true });
        }
    }


    validateForm = () => {

        let firstNameError = "";
        let lastNameError = "";
        let emailError = "";
        let descriptionError = "";
        let titleError = "";
        let phoneNumberError = "";


        if (!this.state.firstName) {
            firstNameError = "first name is empty"
        }

        if (!this.state.lastName) {
            lastNameError = "first name is empty"
        }

        if (!this.state.description) {
            descriptionError = "Description is empty"
        }

        if (!this.state.email.includes("@", ".com")) {
            emailError = "invalid email"
        }

         if (!this.state.title) {
            titleError = "title  is empty"
         }

         if (!this.state.phoneNumber) {
            phoneNumberError = "Number is empty"
        }

        if (emailError || firstNameError || lastNameError || descriptionError || titleError || phoneNumberError) {
            this.setState({ emailError, firstNameError, lastNameError, descriptionError, titleError, phoneNumberError })
            return false
        }
        return true
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const { recaptchaLoad, isVerified } = this.state
        const isValid = this.validateForm()



        if (recaptchaLoad && isVerified && isValid) {
            const { firstName, lastName, title, email, description, phoneNumber } = this.state
            const templateParams = {
                firstName,
                lastName,
                email,
                title,
                phoneNumber,
                description

            }
            emailjs.send(
                'gmail',
                'tbuilders',
                templateParams,
                'user_HeO2ybIbY7V0tpUwqhh8H'
            )

            alert("Success")
            this.closeAfterSubmit()


        }

    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }


    handleModalShowHide = () => {
        this.setState({ showHide: !this.state.showHide })
    }

    closeAfterSubmit = () => {
        this.setState({showHide: !this.state.showHide})
    }
    render(){
        return(
            <div className="">
                <Button variant="primary" onClick={() => this.handleModalShowHide()}>
                   Click here
                </Button>

                <Modal show={this.state.showHide}>

                    <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                    <Modal.Title>Fill out the contact form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                         <div className="">
                <div>


                    <div className="">

                        <Form onSubmit={this.handleSubmit} className="container formModal">

                            <FormGroup className="row justify-content-center">
                                <div className="col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">

                                    <label >First Name</label>
                                <input name="firstName" type="text" className="form-control"
                                        value={this.firstName}
                                        placeholder="First name"
                                        onChange={this.handleChange}
                                    />


                                </div>
                            </FormGroup>
                                        <div style={{color: "red"}}>{this.state.firstNameError}</div>
                            <FormGroup className="row justify-content-center">

                                <div className="col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">

                                    <label>Last Name</label>
                                <input name="lastName" type="text" className="form-control"
                                        value={this.lastName}
                                        placeholder="Last name"
                                        onChange={this.handleChange}
                                    />


                                </div>
                                        </FormGroup>
                                         <div style={{color: "red"}}>{this.state.lastNameError}</div>



                            <FormGroup className="row justify-content-center">
                                <div className="col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">

                                    <label >Email address</label>
                                <input name="email" type="email" className="form-control"
                                        value={this.email}
                                        placeholder="name@example.com"
                                        onChange={this.handleChange}
                                    />


                                </div>

                                        </FormGroup>
                                         <div style={{color: "red", marginTop: ""}}>{this.state.emailError}</div>


                                          <FormGroup className="row justify-content-center">
                                <div className="col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">

                                    <label >Title</label>
                                <input name="title" type="title" className="form-control"
                                        value={this.title}
                                        placeholder="Title"
                                        onChange={this.handleChange}
                                    />


                                </div>
                                        </FormGroup>
  <div style={{color: "red"}}>{this.state.titleError}</div>


                                          <FormGroup className="row justify-content-center">
                                <div className="col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">

                                    <label >Phone Number</label>
                                <input name="phoneNumber" type="number" className="form-control"
                                        value={this.phoneNumber}
                                        placeholder="00000-00000"
                                        onChange={this.handleChange}
                                    />


                                </div>
                            </FormGroup>

  <div style={{color: "red", marginTop: ""}}>{this.state.phoneNumberError}</div>

                            <FormGroup className="row justify-content-center">
                                <div className="col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">

                                    <label >Description</label>
                                    <textarea name="description" className="form-control" value={this.description}
                                        rows="4"
                                        onChange={this.handleChange}
                                    ></textarea>

                                </div>
                            </FormGroup>
                                        <div style={{ color: "red" }}>{this.state.descriptionError}</div>


                            <FormGroup className="row justify-content-center">
                                <div className="col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">
                                    <Recaptcha
                                        sitekey="6LdmE8MZAAAAADNRNnup9dn_spXlHSnc9oMCEULA"
                                        render="explicit"
                                        onloadCallback={this.recaptchaLoaded}
                                        verifyCallback={this.verifiedRecaptcha}
                                    />
                                </div>
                            </FormGroup>


                            <FormGroup className="row justify-content-center">
                                <div className="mb-3 col-xs-12 col-xl-12 col-sm-12 col-md-12 col-lg-12">
                                    <Button variant="warning" onClick={() => this.handleModalShowHide()}><Link to="/">Cancel</Link></Button>{' '}
                                    <Button type="submit" variant="primary" onClick={() => this.handleSubmit}>Submit</Button>
                                </div>
                            </FormGroup>


                        </Form>
                    </div>
                </div>
            </div >


                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

            </div>
        )
    }

}

export default FormModal;